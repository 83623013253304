import React, { FC } from "react";
import { shallowEqual } from "react-redux";
import { DisclaimerLocation } from "../../../../../common-deprecated/types/CommonTypes";
import { useCompareUSCSelector } from "../../../redux/store";
import { disclaimerSelector, UsedCarCompareDisclaimerType } from "../../../utils/disclaimerUtils";
import Text from "../../../../shared-components/toyota/styles/atoms/Text";

type Props = { disclaimerType: UsedCarCompareDisclaimerType; reference?: string };

const InlineDisclaimer: FC<Props> = ({ disclaimerType, reference }) => {
    const disclaimers = useCompareUSCSelector(disclaimerSelector, shallowEqual);
    const inlineDisclaimer = disclaimers.find(
        (disclaimer) =>
            disclaimer.location === DisclaimerLocation.Inline &&
            disclaimer.type === disclaimerType &&
            disclaimer.reference === reference,
    );

    if (!inlineDisclaimer) return null;

    return (
        <Text
            tag="div"
            color="grey-7"
            variant="body-small-regular"
            spacing={{
                mt: "spacing-1",
                mb: "spacing-2",
            }}
        >
            <span dangerouslySetInnerHTML={{ __html: inlineDisclaimer.value }} />
        </Text>
    );
};

export default InlineDisclaimer;
